<template>
  <div class="bg-img min-height flex-column justify-content-start align-items-center">
    <van-form class="width-100" @submit="onSubmit">
      <div class="block3 box-shadow bg-white width-100 br-reg">
        <van-cell-group>
          <div class="width-100 margin-bs flex-row justify-content-spaceBetween align-items-center">
            <div class="width-40 title">
              <p class="fw-mid">报告人信息</p>
            </div>
          </div>
          <van-field
            v-model="form.reportName"
            name="reportName"
            label="报告人"
            placeholder="请输入报告人姓名"
            input-align="right"
            :rules="[{ required: true }]"
          />
          <van-field
            v-model="form.reportMobile"
            name="reportMobile"
            label="报告人电话"
            placeholder="请输入报告人电话号码"
            input-align="right"
            :rules="[{ validator: validatorMobile, message: '请输入正确的手机号码' }]"
          />
          <van-field
            v-model="form.reportDept"
            name="reportName"
            label="报告人单位"
            placeholder="请输入报告人单位"
            input-align="right"
            :rules="[{ required: true }]"
          />
          <div class="width-100 margin-bs margin-t flex-row justify-content-spaceBetween align-items-center">
            <div class="width-40 title">
              <p class="fw-mid">未成年人信息</p>
            </div>
          </div>
          <van-field
            v-model="form.childName"
            name="childName"
            label="未成年人姓名"
            placeholder="请输入未成年人姓名"
            input-align="right"
            :rules="[{ required: true }]"
          />
          <van-field name="childGender" input-align="right" label="未成年人性别">
            <template #input>
              <van-radio-group v-model="form.childGender" direction="horizontal">
                <van-radio name="1">男</van-radio>
                <van-radio name="2">女</van-radio>
              </van-radio-group>
            </template>
          </van-field>

          <van-field v-model="form.childOrg" name="childOrg" label="学校/单位" placeholder="请输入未成年人学校名称" input-align="right" />

          <van-field
            v-model="form.childMobile"
            name="childMobile"
            label="联系方式"
            placeholder="请输入联系方式"
            input-align="right"
            :rules="[{ validator: validatorMobile }]"
          />
          <van-field
            v-model="form.childIdCard"
            name="childIdCard"
            label="身份证号码"
            placeholder="请输入身份证号码"
            input-align="right"
            :rules="[{ validator: validatorIdCard, message: '请输入正确的身份证号码' }]"
          />

          <van-field
            v-model="form.childHometown"
            name="childHometown"
            label="户籍地"
            placeholder="请输入户籍地"
            input-align="right"
            :rules="[{ required: true }]"
          />
          <van-field
            v-model="form.childAddress"
            name="childAddress"
            label="现住址"
            placeholder="请输入现住址"
            input-align="right"
            :rules="[{ required: true }]"
          />

          <div class="width-100 margin-bs margin-t flex-row justify-content-spaceBetween align-items-center">
            <div class="width-60 title">
              <p class="fw-mid">未成年人父母信息</p>
            </div>
          </div>
          <van-field v-model="form.fatherName" name="fatherName" label="父亲姓名" placeholder="请输入父亲姓名" input-align="right" />
          <van-field v-model="form.fatherMobile" name="fatherMobile" label="父亲联系方式" placeholder="请输入父亲联系方式" input-align="right" />
          <van-field v-model="form.fatherOrg" name="fatherOrg" label="父亲住址/单位" placeholder="请输入父亲住址/单位" input-align="right" />
          <van-field v-model="form.motherName" name="motherName" label="母亲姓名" placeholder="请输入母亲姓名" input-align="right" />
          <van-field v-model="form.motherMobile" name="motherMobile" label="母亲联系方式" placeholder="请输入母亲联系方式" input-align="right" />
          <van-field v-model="form.motherOrg" name="motherOrg" label="母亲住址/单位" placeholder="请输入母亲住址/单位" input-align="right" />
          <div class="width-100 margin-bs margin-t flex-row justify-content-spaceBetween align-items-center">
            <div class="width-40 title">
              <p class="fw-mid">陪同人员信息</p>
            </div>
          </div>
          <van-field input-align="right" name="isAccompany" label="是否有陪同人员">
            <template #input>
              <van-switch v-model="form.isAccompany" size="20" @input="onAccompanyChange" />
            </template>
          </van-field>
          <van-field
            v-show="accompanyShow"
            v-model="form.accompanyName"
            name="accompanyName"
            label="姓名"
            placeholder="请输入姓名"
            input-align="right"
            :rules="[{ validator: validatorAccompany }]"
          />
          <van-field
            v-show="accompanyShow"
            v-model="form.accompanyMobile"
            name="accompanyMobile"
            label="联系方式"
            placeholder="请输入联系方式"
            input-align="right"
            :rules="[{ validator: validatorAccompany }]"
          />
          <van-field
            v-show="accompanyShow"
            v-model="form.accompanyOrg"
            name="accompanyOrg"
            label="住址/单位"
            placeholder="请输入住址/单位"
            input-align="right"
            :rules="[{ validator: validatorAccompany }]"
          />
          <div class="width-100 margin-bs margin-t flex-row justify-content-spaceBetween align-items-center">
            <div class="width-40 title">
              <p class="fw-mid">就诊信息</p>
            </div>
          </div>

          <van-field
            v-model="form.diagnoses"
            name="diagnoses"
            label="就诊病症"
            placeholder="请输入就诊病症"
            input-align="right"
            :rules="[{ required: true }]"
          />
          <van-field
            v-model="form.diagnosesDept"
            name="diagnosesDept"
            label="就诊科室"
            placeholder="请输入就诊科室"
            input-align="right"
            :rules="[{ required: true }]"
          />
          <van-field name="compliance" label="符合情形" :rules="[{ required: true, message: '请选择符合的情形' }]">
            <template #input>
              <!-- <van-radio-group v-model="form.compliance" direction="horizontal">
                <van-radio name="1">是</van-radio>
                <van-radio name="0">否</van-radio>
              </van-radio-group> -->
              <van-checkbox-group v-model="compliance">
                <van-checkbox shape="square" :name="item.dictValue" v-for="item in dictList" :key="item.dictValue">{{ item.dictLabel }}</van-checkbox>
              </van-checkbox-group>
            </template>
          </van-field>

          <div class="width-100 margin-bs margin-t flex-row justify-content-spaceBetween align-items-center">
            <div class="width-40 title">
              <p class="fw-mid">侵害信息</p>
            </div>
          </div>

          <van-field
            input-align="right"
            v-model="form.encroachTime"
            is-link
            readonly
            name="encroachTime"
            label="侵害时间"
            placeholder="请选择侵害时间"
            @click="showPicker = true"
          />
          <van-popup v-model="showPicker" position="bottom">
            <van-datetime-picker
              v-model="currentDate"
              type="date"
              title="选择完整时间"
              :min-date="minDate"
              :max-date="maxDate"
              @confirm="onConfirm"
              @cancel="showPicker = false"
            />
          </van-popup>
          <van-field
            v-model="form.encroachLocation"
            name="encroachLocation"
            label="侵害地点"
            placeholder="请输入侵害地点"
            input-align="right"
            :rules="[{ required: true }]"
          />
          <van-field v-model="form.perpetrator" name="perpetrator" label="侵害人" placeholder="请输入侵害人" input-align="right" />
          <p class="fs-mid darkgrey">事件概况</p>
          <van-field
            v-model="form.reportContent"
            rows="4"
            autosize
            label=""
            type="textarea"
            placeholder="请描述事件经过概况"
            :rules="[{ required: true }]"
          />
          <p class="fs-mid darkgrey">附件 <span class="grey">(图片，最多上传9张)</span></p>
          <van-field label="">
            <template #input>
              <van-uploader v-model="imgList" max-count="9" :after-read="afterRead" />
            </template>
          </van-field>
        </van-cell-group>
      </div>

      <div class="width-60 textAlign-c" style="margin: 16px 20%">
        <van-button color="rgb(253,202,61)" class="" round block type="primary" native-type="submit"> 提交 </van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import { dictGet, mandatoryReportSave, uploadFile } from '@/api/reportApi';
import { Toast } from 'vant';
import { validate } from '@/api/loginApi';
export default {
  name: 'mandatoryReportingForm',
  data() {
    return {
      form: {
        zlbId: '',
        reportName: '',
        reportDept: '',
        reportMobile: '',
        childName: '',
        childGender: '1',
        childOrg: '',
        childMobile: '',
        childIdCard: '',
        childAddress: '',
        childHometown: '',
        fatherName: '',
        fatherMobile: '',
        fatherOrg: '',
        motherName: '',
        motherMobile: '',
        motherOrg: '',
        isAccompany: true,
        accompanyName: '',
        accompanyMobile: '',
        accompanyOrg: '',
        diagnoses: '',
        diagnosesDept: '',
        compliance: '',
        encroachTime: '',
        encroachLocation: '',
        perpetrator: '',
        reportContent: '',
        reportImg: '',
        origin: 'wx'
      },
      accompanyShow: true,
      compliance: [],
      dictList: [],
      imgList: [],
      showPicker: false,
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date()
    };
  },
  created() {
    let zlbId = this.$Cookies.get('zlbId');

    if (zlbId == null) {
      this.$router.push('/mandatoryReportingIndex');
    }
    this.form.zlbId = zlbId;
    this.form.reportName = this.$Cookies.get('userName');
    this.form.reportMobile = this.$Cookies.get('userMobile');

    this.validateMobile(this.form.reportMobile);

    dictGet('ht_mandatory_report_compliance').then((response) => {
      if (response.code == 200) {
        this.dictList = response.data;
      }
    });
  },
  methods: {
    onAccompanyChange(val) {
      if (val) {
        this.accompanyShow = true;
      } else {
        this.accompanyShow = false;
      }
    },
    validatorAccompany(val) {
      if (!this.form.isAccompany) {
        return true;
      } else if (!val) {
        return false;
      }
      return true;
    },
    validateMobile(mobile) {
      validate({ mobile: mobile }).then((response) => {
        if (!response.status == '1001') {
          Toast.fail('您不是强制报告克提交人员，请前往线索报告提交');
          setTimeout(function () {
            this.$router.push('/pcFormClueReport');
          }, 1500);
        }
      });
    },
    jumpTo(e) {
      this.$router.push(e);
    },
    onSubmit() {
      for (let i = 0; i < this.compliance.length; i++) {
        if (this.form.compliance == '') {
          this.form.compliance += this.compliance[i];
        } else {
          this.form.compliance += ',' + this.compliance[i];
        }
      }
      let imgs = '';
      for (let i = 0; i < this.imgList.length; i++) {
        if (imgs == '') {
          imgs += this.imgList[i].fileName;
        } else {
          imgs += ',' + this.imgList[i].fileName;
        }
      }
      this.form.reportImg = imgs;
      let toast = Toast.loading({
        message: '提交中...',
        forbidClick: true,
        loadingType: 'spinner'
      });
      mandatoryReportSave(this.form).then((response) => {
        toast.clear();
        if (response.code == 200) {
          Toast.success('提交成功');
          this.reset();
          setTimeout(function () {
            this.$router.push('/mandatoryReportingList');
          }, 1800);
        } else {
          Toast.fail('提交失败，请稍后再试');
        }
      });
    },
    onConfirm(value) {
      this.form.encroachTime = value.getFullYear() + '-' + (value.getMonth() + 1) + '-' + value.getDate();
      this.showPicker = false;
    },
    validatorMobile(val) {
      let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (val == '' || val <= 10 || !reg.test(val)) {
        return false;
      }
      return true;
    },
    validatorIdCard(num) {
      if (!num) {
        return true;
      }
      num = num.toUpperCase(); // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X。
      if (!/(^\d{15}$)|(^\d{17}([0-9]|X)$)/.test(num)) {
        // alert('输入的身份证号长度不对，或者号码不符合规定！n15位号码应全为数字，18位号码末位可以为数字或X。');
        return false;
      }
      // 校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
      // 下面分别分析出生日期和校验位
      let re;
      const len = num.length;
      if (len === 15) {
        re = new RegExp(/^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/);
        const arrSplit = num.match(re); // 检查生日日期是否正确
        const dtmBirth = new Date('19' + arrSplit[2] + '/' + arrSplit[3] + '/' + arrSplit[4]);
        const bGoodDay =
          dtmBirth.getYear() === Number(arrSplit[2]) && dtmBirth.getMonth() + 1 === Number(arrSplit[3]) && dtmBirth.getDate() === Number(arrSplit[4]);
        if (!bGoodDay) {
          // alert('输入的身份证号里出生日期不对！');
          return false;
        } else {
          // 将15位身份证转成18位//校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
          const arrInt = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
          const arrCh = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'];
          let nTemp = 0;
          num = num.substr(0, 6) + '19' + num.substr(6, num.length - 6);
          for (let i = 0; i < 17; i++) {
            nTemp += num.substr(i, 1) * arrInt[i];
          }
          num += arrCh[nTemp % 11];
          return true;
        }
      }
      if (len === 18) {
        re = new RegExp(/^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/);
        const arrSplit = num.match(re); // 检查生日日期是否正确
        const dtmBirth = new Date(arrSplit[2] + '/' + arrSplit[3] + '/' + arrSplit[4]);
        const bGoodDay =
          dtmBirth.getFullYear() === Number(arrSplit[2]) &&
          dtmBirth.getMonth() + 1 === Number(arrSplit[3]) &&
          dtmBirth.getDate() === Number(arrSplit[4]);
        if (!bGoodDay) {
          // alert('输入的身份证号里出生日期不对！');
          return false;
        } else {
          // 检验18位身份证的校验码是否正确。
          // 校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
          const arrInt = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
          const arrCh = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'];
          let nTemp = 0;
          for (let i = 0; i < 17; i++) {
            nTemp += num.substr(i, 1) * arrInt[i];
          }
          const valnum = arrCh[nTemp % 11];
          if (valnum !== num.substr(17, 1)) {
            // alert('18位身份证的校验码不正确！应该为：'+valnum);
            return false;
          }
          return true;
        }
      }
      return false;
    },
    reset() {
      this.form = {
        zlbId: '',
        reportName: '',
        reportDept: '',
        reportMobile: '',
        childName: '',
        childGender: '1',
        childOrg: '',
        childMobile: '',
        childIdCard: '',
        childAddress: '',
        childHometown: '',
        fatherName: '',
        fatherMobile: '',
        fatherOrg: '',
        motherName: '',
        motherMobile: '',
        motherOrg: '',
        isAccompany: true,
        accompanyName: '',
        accompanyMobile: '',
        accompanyOrg: '',
        diagnoses: '',
        diagnosesDept: '',
        compliance: '',
        encroachTime: '',
        encroachLocation: '',
        perpetrator: '',
        reportContent: '',
        reportImg: '',
        origin: '1'
      };
      this.imgList = [];
    },
    afterRead(file) {
      file.status = 'uploading';
      file.message = '上传中...';

      let formFile = new FormData();
      formFile.append('file', file.file);

      uploadFile(formFile).then((response) => {
        if (response.code == 200) {
          file.status = 'done';
          file.message = '';
          file.url = response.url;
          file.fileName = response.fileName;
        } else {
          file.status = 'failed';
          file.message = '上传失败';
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
/deep/.van-field__label {
  width: 6.4em;
}
.bg-img {
  background: url('../../assets/image/bg3.jpg') center top no-repeat;
  background-size: 100%;
  background-color: #4f9eea;
  width: 100%;
  padding: 14rem 5% 2rem;
}
.block3 {
  padding: 2rem 5%;
  margin-bottom: 3rem;
  .title {
    line-height: 2rem;
    //background: url("../../assets/image/5.png") left bottom no-repeat;
    //background-size: contain;
    p {
      color: #4f9eea;
      font-size: 1.2rem;
    }
  }
  .list-box {
    width: 100%;
    padding: 1rem;
    background: rgb(241, 240, 254);
    .label {
      right: 0;
      top: 0.5rem;
      padding: 0.1rem 0.5rem 0.1rem 1rem;
    }
    .label1 {
      background: url('../../assets/image/pic3.png') center no-repeat;
      background-size: 100%;
    }
    .label2 {
      background: url('../../assets/image/pic4.png') center no-repeat;
      background-size: 100%;
    }
    .label3 {
      background: url('../../assets/image/pic5.png') center no-repeat;
      background-size: 100%;
    }
  }
}
/deep/.van-hairline--top-bottom::after {
  border: none;
}
/deep/.van-cell {
  //background: #fafafa;
  //padding: 6px 16px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 10px;
  padding: 10px 0;
}
/deep/.van-cell::after {
  border: none;
}
/deep/.van-switch__node {
  width: 1.5em;
  height: 1.5em;
}
/deep/.van-switch {
  width: 1.7em;
}
</style>
